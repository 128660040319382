import React from "react";
import Layout from "../components/layouts/layout";

export default function Contacto() {


    return (
        <Layout>
            <iframe
                src={"https://riverorenta.mx/valua-tu-carro/year/?cid=0&udid=0"}
                style={{
                    width: '100%',
                    height: 1100,
                    border: "none",
                }}
            />
        </Layout>
    )
}